import React from 'react';
import SectionHeading from './SectionHeading';
import Ratings from './Ratings';

export default function Skills({ data }) {
  const { sectionHeading, allSkills } = data;
  return (
    <section className="section skills-section" id="skills">
      <div className="skills-section">
        <div
          className="container"
          data-aos="fade"
          data-aos-duration="1200"
        >
          <SectionHeading
            miniTitle={sectionHeading.miniTitle}
            title={sectionHeading.title}
          />
          <div className='row'>
            {allSkills.map((item, index) => (
              <div className='col-md-3 col-6' key={index}>
                <div className='skill-box'  data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay="100">
                  <div className='skill-img'>
                    <img src={item.src} alt={item.alt} />
                  </div>
                  <div className='skill-text'>
                    <h3>{item.skilltext}</h3>
                    <div className="rating-wrap">
                      <Ratings ratings={item.ratings} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section >
  );
}
