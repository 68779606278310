import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  // Handler for input field changes
  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.target);

    formData.append('access_key', '50c2b6b4-3a01-4b89-b8d2-21dae4fdc2d2');

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: json,
    }).then(res => res.json());

    if (res.success) {
      setFormData({ name: '', email: '', subject: '', message: '' });
      setLoading(false);
      toast.success('Message sent successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setLoading(false);
      toast.error('Error sending message. Please try again later.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  
  return (
    <div>
      <form id="contact-form" onSubmit={onSubmit}>
        <div className="row gx-3 gy-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Your Name</label>
              <input
                name="name"
                placeholder="Name *"
                className="form-control"
                type="text"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label">Your Email</label>
              <input
                name="email"
                placeholder="Email *"
                className="form-control"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="form-label">Subject</label>
              <input
                name="subject"
                placeholder="Subject *"
                className="form-control"
                type="text"
                value={formData.subject}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="form-label">Your message</label>
              <textarea
                name="message"
                placeholder="Your message *"
                rows={4}
                className="form-control"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="send">
              <button
                className={`px-btn w-100 ${loading ? 'disabled' : ''}`}
                type="submit"
              >
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}
